<template>
    <div class="instructor-details-desc">
        <h3 class="text-center">Dashboard</h3>
        <div class="row justify-content-center pt-2"  v-if="!isLoading">
            <div class="col-md-4 pt-2">
                <div class="single-instructor-member mb-30 shadow-lg">
                    <router-link :to="{ name: 'TraineeCourses' }">
                        <div class="member-image text-default">
                            <svg height="100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
                        </div>
                        <div class="member-content">
                            <h6 class=" text-default">
                                <span v-if="dashboard.courses == 0">No Courses Subscribed Yet</span>
                                <span v-else-if="dashboard.courses == 1">{{ dashboard.courses }} Course on Progress</span>
                                <span v-else>{{ dashboard.courses }} Courses on Progress</span>
                                 
                            </h6>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-md-4 pt-2">
                <div class="single-instructor-member mb-30 shadow-lg">
                    <router-link :to="{ name: 'TrainerClassRoom' }">
                        <div class="member-image text-default">
                            <svg height="100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                        </div>
                        <div class="member-content">
                            <h6 class=" text-default">
                                <span v-if="dashboard.classrooms == 0">No Classrooms Yet</span>
                                <span v-else-if="dashboard.classrooms == 1">{{ dashboard.classrooms }} Classroom</span>
                                <span v-else>{{ dashboard.classrooms }} Classrooms</span>
                            </h6>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else>
            <Loading />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            dashboard: {},
            errors: {},        
        }
    },
    methods:{
        getDashboardData(){
            axios.get('https://apitraining.vipawaworks.com/api/user/get_trainee_data').then(response => {
                this.dashboard = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getDashboardData() 
    }
}
</script>

<style>

</style>