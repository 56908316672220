<template>
<Navbar :user="user" />
<section class="instructor-details-area pt-100 pb-70">
  <div class="container">
    <div class="row" v-if="!isLoading">
      <div class="col-md-2" id="sticky-sidebar">
        <div class="sticky-top">
        <Sidebar :user="user" />
        </div>
      </div>
      <div class="col" id="main">
        <div class="pb-70" v-if="user.status == 3">
          <TrainerCompany />
        </div>
        <div class="pb-70" v-else-if="user.status == 2">
          <TrainerStats />
        </div>
        <div class="pb-70" v-else>
          <TraineeStats />
        </div>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</section>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import TrainerStats from '@/components/trainer/dashboard/TrainerStats.vue'
import TraineeStats from '@/components/trainee/dashboard/TraineeStats.vue'
import Sidebar from '@/components/trainer/Sidebar.vue'
import TrainerCompany from '@/components/company/dashboard/TrainerCompany.vue'
// import SingleCourse from '@/components/SingleCourse.vue'
export default {
 components: { Navbar,Loading,TrainerStats,TraineeStats, Sidebar,TrainerCompany },
  data(){
    return {
      drawer: true,
      currentUser: {},
      user: {},
      jobs: {},
      token: localStorage.getItem('user_data'),
      subscriber: {},
      courses: {},
      isLoading: true,
    }
  },
  methods: {
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Dashboard - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser()
  }

}
</script>

<style>

</style>